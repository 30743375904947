import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import AuthProvider from "./context/AuthProvider";
import CustomerProvider from "./context/CustomerProvider";
import "./index.css";
import router from "./routes";
import "./i18n.js";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
      console.log("ServiceWorker unregistered:", registration.scope);
    });
  });

  // Clear all caches
  caches.keys().then((cacheNames) => {
    cacheNames.forEach((cacheName) => {
      caches.delete(cacheName);
      console.log("Cache deleted:", cacheName);
    });
  });
}

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <React.Suspense>
      <AuthProvider>
        <CustomerProvider>
          <RouterProvider router={router} />
        </CustomerProvider>
      </AuthProvider>
    </React.Suspense>
  </React.StrictMode>
);
