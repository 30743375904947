import { Button } from "@/components/Button";
import { useAuth } from "@/context/AuthProvider";
import { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { apiUrl } from "@/config";


export default function Page12() {
    const { t } = useTranslation();
    const [customerName, setCustomerName] = useState("");
    const { profile } = useAuth();
    const [customerEmail, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        const respones = await fetch(`${apiUrl}/api/v1/mail`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
                customerEmail,
                bussinessEmail: profile?.email,
                customerName,
                bussinessName: profile?.name,
                phoneNumber: phone
            }),
        });
        const json = await respones.json();
        navigate("/page-13");

        fetch(`${apiUrl}/api/v1/statistics/` + profile?._id, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({ statName: "formsSent" }),
        });

        return json;
    };

    return (
        <section className="text-center w-full max-w-sm" role="application">
            <h1 className="text-xl sm:text-2xl mb-2">{t('page_12.tx_1')}</h1>
            <p className="text-lg sm:text-xl">{t('page_12.tx_2')}</p>
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-4 mx-4">
                <label className="flex flex-col">
                    <span className="sr-only">{t('page_12.tx_3')}</span>
                    <input
                        onChange={(event) => setCustomerName(event.target.value)}
                        className="rounded-full border border-primary bg-input px-2.5 py-1 placeholder:text-gray-500"
                        type="text"
                        name="customerName"
                        id="customerName"
                        placeholder={t('page_12.tx_3') as string}
                    />
                </label>
                <label className="flex flex-col">
                    <span className="sr-only">{t('page_12.tx_4')}</span>
                    <input
                        onChange={(event) => setEmail(event.target.value)}
                        className="rounded-full border border-primary bg-input px-2.5 py-1 placeholder:text-gray-500"
                        type="email"
                        name="email"
                        id="email"
                        placeholder={t('page_12.tx_4') as string}
                    />
                </label>
                <label className="flex flex-col">
                    <span className="sr-only">{t('page_12.tx_5')}</span>
                    <input
                        onChange={(event) => setPhone(event.target.value)}
                        className="rounded-full border border-primary bg-input px-2.5 py-1 placeholder:text-gray-500"
                        type="tel"
                        name="phone"
                        id="phone"
                        placeholder={t('page_12.tx_5') as string}
                    />
                </label>
                <div>
                    <Link to="/page-13">
                        <Button onClick={handleSubmit} className="bg-primary text-white">{t('common.send')}</Button>
                    </Link>
                    <Link to="/page-13">
                        <Button>{t('common.next_time')}</Button>
                    </Link>
                </div>
            </form>
        </section>
    );
}
