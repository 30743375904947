import { AudioPlayer } from "@/components/AudioPlayer";
import { useAuth } from "@/context/AuthProvider";
import { useCustomer } from "@/context/CustomerProvider";
import PlayerProvider from "@/context/PlayerProvider";
import useFetch from "@/hooks/useFetch";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiUrl } from "@/config";

export default function Page08() {
  const { profile } = useAuth();
  const { customerInfo, changeCustomerInfo } = useCustomer();
  const { t } = useTranslation();
  const intro =
    localStorage.getItem("i18nextLng") == "he"
      ? profile?.audioHE
      : profile?.audioEN;

  const randomMeditationUrl = `${apiUrl}/api/v1/meditations/random?gender=${customerInfo.gender?.toUpperCase()}&tags=${
    customerInfo.tag === "recommendations" ? "airbaloon" : customerInfo.tag
  }&lang=${localStorage.getItem("i18nextLng")}`;

  const randomRecommendationUrl = `${apiUrl}/api/v1/recommendations/random?gender=${customerInfo.gender?.toUpperCase()}&lang=${localStorage.getItem(
    "i18nextLng"
  )}&user=${profile?._id}&experienced=${customerInfo.experience}`;

  const { data: meditation } = useFetch<{ data: { audio: string } }>(
    randomMeditationUrl
  );

  const { data: recommendation } = useFetch<{ data: { audio: string } }>(
    randomRecommendationUrl
  );

  const [playlist, setPlaylist] = useState([{ src: intro, title: "הקדמה" }]);

  const getRandomRecommendation = async () => {
    const response = await fetch(randomRecommendationUrl, {
      credentials: "include",
    });
    const json = await response.json();
    setPlaylist([playlist[0], { src: json.data.audio, title: "מדיטציה" }]);
  };

  const getRandomMeditation = async () => {
    const response = await fetch(
      `${apiUrl}/api/v1/meditations/random?gender=${customerInfo.gender?.toUpperCase()}&lang=${localStorage.getItem(
        "i18nextLng"
      )}`,
      {
        credentials: "include",
      }
    );
    const json = await response.json();
    setPlaylist([playlist[0], { src: json.data.audio, title: "מדיטציה" }]);
  };

  const handleSrcChange = async () => {
    await fetch(`${apiUrl}/api/v1/statistics/` + profile?._id, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({ statName: "finish" }),
    });
    changeCustomerInfo({ ...customerInfo, finished: true });
  };

  useEffect(() => {
    // Determine which audio to use based on tag and availability
    const audioSource =
      customerInfo.tag === "recommendations" && recommendation?.data
        ? recommendation?.data?.audio // Use recommendation first, fallback to meditation
        : meditation?.data?.audio; // Use meditation if tag is not recommendations

    if (audioSource) {
      setPlaylist([
        playlist[0], // Keep the intro
        { src: audioSource, title: "מדיטציה" },
      ]);
    }
  }, [meditation, recommendation]); // Update when either meditation or recommendation changes

  return (
    <section className="text-center w-full max-w-sm" role="application">
      <h1 className="text-4xl mb-8">{t("page_8.tx_1")}</h1>
      <PlayerProvider onSrcChange={handleSrcChange} playlist={playlist}>
        <AudioPlayer />
        <button
          onClick={
            customerInfo.tag === "recommendations" && recommendation?.data
              ? getRandomRecommendation
              : getRandomMeditation
          }
          className="hover:underline"
        >
          {t("page_8.tx_2")}
        </button>
      </PlayerProvider>
    </section>
  );
}
