import { useTranslation } from "react-i18next";
import { useAuth } from "@/context/AuthProvider";
import { appId } from "@/config";
import { fbIcon, instIcon } from "./FBIcons";


const FacebookShareButton = () => {
    const { t } = useTranslation();
    const { profile } = useAuth();

    const facebookShareLink = `https://www.facebook.com/dialog/feed?app_id=${appId}&display=popup&link=${profile?.imageFB}`;
        return (
            <a id='fbBtn' className='mb-4' href={facebookShareLink} target="_blank" rel="noopener noreferrer">
                <span className="text-3x1" style={localStorage.getItem('language') == 'he' ? {left: '15px'} : {left: '10px'}}>
                    <div id="fbTxt">
                        {t('page_11.tx_2')}
                    </div>
                    <img key='fbIcn' alt="fbIcn" className="fbIcn" src={fbIcon}></img>
                    <img key='instIcn' alt='instIcn' className="instIcn" src={instIcon}></img>
                </span>
            </a>
        );
};

export default FacebookShareButton;