import { useCustomer } from "@/context/CustomerProvider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/context/AuthProvider";

export default function Page06() {
  const { t } = useTranslation();
  const { changeCustomerInfo } = useCustomer();
  const { profile } = useAuth();

  return (
    <section className="text-center w-fit max-w-sm" role="application">
      <h1 className="text-4xl mb-8">{t("page_6.tx_1")}</h1>
      <div
        className={`grid grid-cols-2 w-fit max-w-xs mx-auto ${
          profile?.recommendations ? "gap-x-16 gap-y-1" : "gap-4"
        }`}
      >
        <Link className={profile?.recommendations ? "w-28" : ""} to="/page-08">
          <button
            onClick={() => changeCustomerInfo({ tag: "sea" })}
            aria-labelledby="sea"
          >
            <img src="/img/sea.png" alt={t("page_6.tx_6") as string} />
            <span id="sea">{t("page_6.tx_2")}</span>
          </button>
        </Link>
        <Link className={profile?.recommendations ? "w-28" : ""} to="/page-08">
          <button
            onClick={() => changeCustomerInfo({ tag: "desert" })}
            aria-labelledby="desert"
          >
            <img src="/img/desert.png" alt={t("page_6.tx_7") as string} />
            <span id="desert">{t("page_6.tx_3")}</span>
          </button>
        </Link>
        {profile?.recommendations && (
          <Link className="col-span-2 w-28 justify-self-center" to="/page-08">
            <button
              onClick={() => changeCustomerInfo({ tag: "recommendations" })}
              aria-labelledby="airbaloon"
            >
              <div className="relative">
                <img
                  src="/img/recommendations.png"
                  alt={t("page_6.tx_11") as string}
                />
                <img
                  className="absolute w-14 top-1/2 right-[52px] -translate-y-1/3 translate-x-1/2"
                  src={profile?.image}
                  alt={t("page_6.tx_11") as string}
                />
              </div>
              <span id="airbaloon">{t("page_6.tx_10")}</span>
            </button>
          </Link>
        )}
        <Link className={profile?.recommendations ? "w-28" : ""} to="/page-08">
          <button
            onClick={() => changeCustomerInfo({ tag: "airbaloon" })}
            aria-labelledby="airbaloon"
          >
            <img src="/img/airbaloon.png" alt={t("page_6.tx_4") as string} />
            <span id="airbaloon">{t("page_6.tx_4")}</span>
          </button>
        </Link>
        <Link className={profile?.recommendations ? "w-28" : ""} to="/page-08">
          <button
            onClick={() => changeCustomerInfo({ tag: "ski" })}
            aria-labelledby="ski"
          >
            <img src="/img/ski.png" alt={t("page_6.tx_5") as string} />
            <span id="ski">{t("page_6.tx_5")}</span>
          </button>
        </Link>
      </div>
    </section>
  );
}
