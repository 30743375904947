import createCtx from "@/hooks/createCtx";
import {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

interface PlaylistItem {
  src: string | undefined;
  title: string;
}

interface PlayerProviderProps extends PropsWithChildren {
  playlist: PlaylistItem[];
  onSrcChange?: () => void;
}

interface PlayerContext {
  playlist: PlaylistItem[];
  currentIndex: number;
  isPlaying: boolean;
  duration: number;
  currentTime: number;
  calculateTime: (secs: number) => string;
  audioPlayer: MutableRefObject<HTMLAudioElement | null>;
  progressBar: MutableRefObject<HTMLInputElement | null>;
  animationRef: MutableRefObject<number>;
  togglePlayPause: () => void;
  whilePlaying: () => void;
  changeRange: () => void;
  handleLoadedMetadata: () => void;
  handleEnded: () => void;
}

export const [usePlayer, Player] = createCtx<PlayerContext>();

export default function PlayerProvider({
  children,
  playlist,
  onSrcChange,
}: PlayerProviderProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const audioPlayer = useRef<HTMLAudioElement>(null);
  const progressBar = useRef<HTMLInputElement>(null);
  const animationRef = useRef<number>(0);

  const calculateTime = (secs: number) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    if (audioPlayer.current && progressBar.current) {
      const prevValue = isPlaying;
      setIsPlaying(!prevValue);
      if (!prevValue) {
        audioPlayer.current.play();
        animationRef.current = requestAnimationFrame(whilePlaying);
      } else {
        audioPlayer.current.pause();
        cancelAnimationFrame(animationRef.current);
      }
    }
  };

  const whilePlaying = () => {
    if (audioPlayer.current && progressBar.current) {
      progressBar.current.value = String(audioPlayer.current.currentTime);
      setCurrentTime(Number(progressBar.current.value));
      animationRef.current = requestAnimationFrame(whilePlaying);
    }
  };

  const changeRange = () => {
    if (audioPlayer.current && progressBar.current) {
      audioPlayer.current.currentTime = Number(progressBar.current.value);
      setCurrentTime(Number(progressBar.current.value));
    }
  };

  const handleLoadedMetadata = () => {
    if (audioPlayer.current && progressBar.current) {
      const seconds = Math.floor(audioPlayer.current.duration);
      setDuration(seconds);
      progressBar.current.max = String(seconds);
    }
  };

  const handleEnded = () => {
    if (currentIndex < playlist.length - 1) {
      setIsPlaying(false);
      setCurrentIndex(currentIndex + 1);
      onSrcChange && onSrcChange();
    } else {
      navigate("/page-09");
    }
  };

  useEffect(() => {
    if (audioPlayer.current && currentIndex !== 0) {
      audioPlayer.current.currentTime = 0;
      audioPlayer.current.play();
      setIsPlaying(true);
    }
  }, [playlist]);

  useEffect(() => {
    togglePlayPause();
  }, [currentIndex]);

  const value: PlayerContext = {
    currentIndex,
    playlist,
    isPlaying,
    handleEnded,
    duration,
    currentTime,
    calculateTime,
    audioPlayer,
    progressBar,
    animationRef,
    togglePlayPause,
    whilePlaying,
    changeRange,
    handleLoadedMetadata,
  };

  return <Player value={value}>{children}</Player>;
}
